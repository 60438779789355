'use client';

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { ElementalArea } from '@/components/Blocks/ElementalArea';
import { BlogPostCard } from '@/components/Cards/BlogPostCard';
import { CmsHtmlContent } from '@/components/CmsHtmlContent';
import { PageHeader } from '@/components/Pages/components/PageHeader';
import { PageWrap } from '@/components/Pages/components/PageWrap';
import { SiteContainer } from '@/components/SiteContainer';
import { PageProps } from '@/lib/PageProps';
import { CmsBlogPostSummary } from '@/services/cms/models/CmsBlogPostSummary';
import { CmsBlogTag } from '@/services/cms/models/CmsBlogTag';
import { CmsSiteConfig } from '@/services/cms/models/CmsSiteConfig';
import { CmsBlogPageData } from '@/services/cms/pages/CmsBlogPageData';

export interface BlogPageProps extends PageProps<{ slug: string[] }> {
    pageData: CmsBlogPageData;
    siteConfig: CmsSiteConfig;
    locale?: string;
}

export const BlogPage = (props: BlogPageProps) => (
    <>
        <PageHeader {...props} />
        <PageWrap pageData={props.pageData}>
            <SiteContainer sx={{ paddingBottom: 6 }}>
                <CmsHtmlContent html={props.pageData.content} />
                <TagFilterBar tags={props.pageData.tags} />
                <BlogSummaryCardGrid posts={props.pageData.posts} postsPerPage={props.pageData.postsPerPage} />
            </SiteContainer>
            <ElementalArea wrap={true}>{props.pageData.elementalArea}</ElementalArea>
        </PageWrap>
    </>
);

const TagFilterBar = ({ tags }: { tags?: CmsBlogTag[] }) => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const activeTag = searchParams?.get('tag');
    return tags?.length ? (
        <Box sx={{ py: 2 }}>
            <Button component={Link} href={pathname || ''} shallow={true} variant={!activeTag ? 'outlined' : 'text'}>
                All tags
            </Button>
            {tags?.map((tag) => (
                <Button component={Link} key={tag.id} href={`?tag=${tag.urlSegment}`} shallow={true} variant={activeTag === tag.urlSegment ? 'outlined' : 'text'}>
                    {tag.title}
                </Button>
            ))}
        </Box>
    ) : null;
};

const BlogSummaryCardGrid = ({ posts, postsPerPage = 10 }: { postsPerPage?: number; posts?: CmsBlogPostSummary[] }) => {
    const { page, activeTag } = useBlogSearchParams();
    const filteredPosts = posts?.filter((post) => {
        if (!activeTag) {
            return true;
        }
        return post.tags?.includes(activeTag);
    });
    const paginatedPosts = usePagination({
        page,
        items: filteredPosts || [],
        totalItems: filteredPosts?.length || 0,
        itemsPerPage: postsPerPage || 10,
    }).paginatedItems;
    if (!filteredPosts?.length) {
        return <Typography>There are no blog posts {activeTag ? <>tagged &quot;{activeTag}&quot;</> : null}</Typography>;
    }
    return (
        <>
            <Grid container spacing={2}>
                {paginatedPosts.map((blogPost) => (
                    <Grid component="article" key={`blog-post-${blogPost.id}`} sm={6} md={4}>
                        <BlogPostCard blogPost={blogPost} />
                    </Grid>
                ))}
            </Grid>
            <BlogPagePagination posts={filteredPosts} postsPerPage={postsPerPage} />
        </>
    );
};

const usePagination = <T,>({ page, items, totalItems, itemsPerPage }: { items: T[]; page: number; totalItems: number; itemsPerPage: number }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const offset = itemsPerPage * (page - 1);
    const paginatedItems = items.slice(offset, itemsPerPage * page);
    return { paginatedItems, offset, totalPages };
};

const BlogPagePagination = ({ posts, postsPerPage }: { postsPerPage: number; posts: CmsBlogPostSummary[] }) => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const { page } = useBlogSearchParams();
    const itemsPerPage = postsPerPage || 10;
    const totalPosts = posts?.length || 0;
    const pages = Math.ceil(totalPosts / itemsPerPage);
    return (
        <Stack sx={{ alignItems: 'center', pt: 2 }}>
            <Pagination
                page={page}
                count={pages}
                variant="outlined"
                color="primary"
                renderItem={(item) => {
                    const itemPageNumber = item.page;
                    if (item.disabled || !itemPageNumber) {
                        return <PaginationItem {...item} />;
                    }
                    const params = new URLSearchParams(searchParams || '');
                    params.set('page', itemPageNumber.toString());
                    const qs = params.toString();
                    return <PaginationItem component={Link} href={`${pathname}${qs ? `?${qs}` : ''}`} {...item} />;
                }}
            />
        </Stack>
    );
};

const useBlogSearchParams = () => {
    const searchParams = useSearchParams();
    const activeTag = searchParams?.get('tag');
    const page = Number(searchParams?.get('page') || '1');

    return { page, activeTag };
};
