import { datadogRum } from '@datadog/browser-rum';
import { env } from '@/env';
import { jucyInsights } from './jucyInsights';

if (env.NEXT_PUBLIC_DD_APP_ID && env.NEXT_PUBLIC_DD_CLIENT_TOKEN) {
    datadogRum.init({
        applicationId: env.NEXT_PUBLIC_DD_APP_ID,
        clientToken: env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
        site: env.NEXT_PUBLIC_DD_SITE,
        service: env.NEXT_PUBLIC_DD_SERVICE,
        env: env.NEXT_PUBLIC_ENV,
        version: env.NEXT_PUBLIC_VERSION || '0',
        sessionSampleRate: 25,
        sessionReplaySampleRate: 25,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        enableExperimentalFeatures: ['feature_flags'],
        defaultPrivacyLevel: 'allow',
        allowedTracingUrls: [(url) => jucyInsights.shouldTraceUrl(url)],
        proxy: (options) => `https://${jucyInsights.resolveInsightsDomain(window.location.href)}/ingest${options.path}?${options.parameters}`,
    });
}
